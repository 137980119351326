<template>
  <section class="product-container" ref="product-container">
    <div class="product-main">
      <div class="product-list" v-for="item in dataList" :key="item.id" @click="submit(item.id)">
        <div class="shopCover"><img alt="logo" :src="item.cover" /></div>
        <div class="title">{{item.name}}</div>
        <div class="desc">{{item.buyPoint}}</div>
        <div class="cost">
          <van-button type="primary" size="mini" round class="submit">免费领取</van-button>
        </div>
      </div>
    </div>
    <div v-if="isFinshed" class="emptyData">
      <p>没有更多了~</p>
    </div>
  </section>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  computed,
  onMounted,
  onUnmounted,
} from 'vue'
import { useRoute } from "vue-router";
export default {
  setup() {
    const state = reactive({
      agentCode : '',
      page : 1,
      limit : 10,
      dataList : [],
      isFinshed: false
    })
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $http: http, $router : router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    state.agentCode = route.query.agentCode
    const methods = {
      // 获取产品列表
      getProductList(){
        http.get(`/api/zyadposition/numberCardList`,{
          params :{
            agentCode : state.agentCode,
            page : state.page,
            limit : state.limit
          }
        }).then(({data : res})=>{
          if(res.code == 0){
            state.dataList = state.dataList.concat(res.data)
            res.data.length == 0 ? state.isFinshed = true : state.isFinshed = false
          }
        })
      },
      // 免费领取
      submit(id){
        router.push({
          name : `personal-newcarduser`,
          query : {
            agentId : state.agentCode, 
            goodsId: id
          }
        })
      },
      /**
			 * @funcHandle handleScroll-滚动条句柄
			 * @params htmlHeight-当前页面的总高度  clientHeight-当前页面在浏览器中的可视高度  scrollTop-当前浏览器滚动条到top位置的高度
			 *
			 */
			handleScroll(){
				let htmlHeight= ctx.$refs['product-container'].scrollHeight
				let totalHeight = ctx.$refs['product-container'].clientHeight + ctx.$refs['product-container'].scrollTop + 2 //小米系列机型适配处理
				if(totalHeight > htmlHeight){
					state.page++
          ctx.getProductList()
				}
			}
    }
    onMounted(() => {
      ctx.getProductList()
      window.addEventListener('scroll', ctx.handleScroll, true);
    })
    onUnmounted(() => {
      window.removeEventListener("scroll", ctx.handleScroll, true);
    })
    return {
      ...toRefs(state),
      ...methods,
    }
  },
}
</script>

<style lang="scss" scoped>
.product-container {
  padding: 10px;
  padding: 10px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(250,250,250,1);
  .product-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .product-list{
      width: 340px;
      height: 590px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-left: 12px;
      background: #fff;
      border-radius: 16px;
      .shopCover{
        width: 340px;
        height: 340px;
        text-align: center;
        img{
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
      }
      .title{
        color: #061029;
        font-size: 4.267vw;
        padding: 0px 3.2vw 0px 3.2vw;
        width: 38.667vw;
        height: 6.467vw;
        word-break: break-all;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 3.2vw 0vw 3.2vw;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .desc{
        font-size: 26px;
        color: #A1A8B7;
        width: 290px;
        height: 52px;
        word-break: break-all;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px 3.2vw 3.2vw 3.2vw;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .cost{
        text-align: center;
        padding: 0px 24px 24px 24px;
        .submit{
          font-size: 28px;
          width: 292px;
          height: 68px;
          border: none;
          background: linear-gradient(137.7deg, rgba(255,153,0,1) 0%, rgba(255,106,58,1) 100%);
        }
      }
    }
  }
  .emptyData{
    font-size: 26px;
    text-align: center;
    color: #a1a8b7;
  }
}
</style>